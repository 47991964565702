
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function () {
     $('.nav-item .nav-link').on('click', function (event) {
        if ($(".navbar-toggler").css("display") == "none") {
            var url = $(this).attr('data-rel');
            location.assign(url);
        }
     });
});