
/**
 * Send a form details to the sendmail.php file.
 */

+function ($) {

    page.initMailer = function () {

        var validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        $('[data-form="mailer"]').each(function () {

            var form = $(this),
                email = form.find('[name="email"]'),
                message = form.find('[name="message"]'),
                id = form.find('[name="FID"]'),
                onSuccess = form.dataAttr('on-success', null),
                onError = form.dataAttr('on-error', null);

            form.on('submit', function (e) {
                e.preventDefault();
                e.stopPropagation();

                var msg = $("body").find('#form-msg-' + id.val());
                msg.html('');

                form.children('.alert-danger').remove();

                form.find('[isrequired]').each(function () {
                    if ($(this).is("div")) {
                        var hasCheckedOne = false;
                        $(this).find("input").each(function () {
                            if ($(this).prop("checked") === true) {
                                hasCheckedOne = true;
                            }
                        })

                        if (hasCheckedOne === false) {
                            $(this).addClass('is-invalid');
                            msg.addClass('alert-warning');
                            msg.fadeIn(1000);
                            msg.html(msg.html() + '<li><strong>' + form.find("label[for='" + $(this).attr('id') + "']").text() + '</strong> is required.</li>');

                        } else {
                            $(this).removeClass('is-invalid');
                        }

                    } else {
                        if ($(this).val().length < 1) {
                            $(this).addClass('is-invalid');

                            msg.addClass('alert-warning');
                            msg.fadeIn(1000);
                            msg.html(msg.html() + '<li><strong>' + form.find("label[for='" + $(this).attr('id') + "']").text() + '</strong> is required.</li>');
                        }
                        else {
                            $(this).removeClass('is-invalid');
                        }
                    }
                });

                form.find('[type="email"]').each(function () {
                    if (!validEmail.test($(this).val())) {
                        $(this).addClass('is-invalid');
                    }
                    else {
                        $(this).removeClass('is-invalid');
                    }
                });



                if (email.length) {
                    if (email.val().length < 1 || !validEmail.test(email.val())) {
                        email.addClass('is-invalid');
                        //return false;
                    }
                }


                if (message.length) {
                    if (message.val().length < 1) {
                        message.addClass('is-invalid');
                        //return false;
                    }
                }

                if (form.find('.is-invalid').length) {
                    return false;
                }

                if (form.find('[name ="g-recaptcha-response"]').val() != '') {

                    $.ajax({
                        type: "POST",
                        url: form.attr('action'),
                        data: form.serializeArray(),
                    })
                        .done(function (data) {
                            if ('Success!' === data.Status) {

                                msg.removeClass('alert-danger');
                                msg.removeClass('alert-warning');
                                msg.addClass('alert-success');
                                msg.fadeIn(1000);
                                //msg.html('<h3>' + data.Status + '</h3><p>' + data.Message + '</p><pre>' + data.Debug + '</pre>');
                                msg.html('<h3>' + data.Status + '</h3><p>' + data.Message + '</p>');
                                console.log(data.Status + ': ' + data.Debug);

                                var frm = $("body").find('#form-' + id.val());
                                frm.hide();
                                frm.find('[name="CFT"]').val('');

                                if (onSuccess !== null) {
                                    window[onSuccess]();
                                }
                            }
                            else {

                                if ('Redirect' === data.Status) {
                                    window.location = data.RedirectUrl;

                                } else {

                                    msg.removeClass('alert-success');
                                    msg.removeClass('alert-warning');
                                    msg.addClass('alert-danger');
                                    msg.fadeIn(1000);
                                    //msg.html('<h3>' + data.Status + '</h3><p>' + data.Message + '</p><pre>' + data.Debug + '</pre>');
                                    msg.html('<h3>' + data.Status + '</h3><p>' + data.Message + '</p>');
                                    console.log(data.Status + ': ' + data.Debug);

                                    if (onError !== null) {
                                        window[onError](data.reason);
                                    }
                                }
                            }
                        });
                }
                else {
                    msg = $('#form-msg-' + id.val());
                    msg.addClass('alert-warning');
                    msg.fadeIn(1000);
                    msg.html("Please check the <strong>'I'm not a robot'</strong> reCAPTCHA below.");
                }

                return false;
            });


            form.find('[isrequired], [type="email"]').each(function () {
                $(this).on('focus', function () {
                    $(this).removeClass('is-invalid');
                });
            });


            email.on('focus', function () {
                $(this).removeClass('is-invalid');
            });

            message.on('focus', function () {
                $(this).removeClass('is-invalid');
            });

        });

    }

}(jQuery);

